<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="settings-title mb-3">
          {{ $t('components.form.credits.credit-informations.label') }}
        </div>
        <b-row>
          <b-col cols="4">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits.credit-informations.name') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <d-text-field
                    v-model="credit.name"
                    :rules="validation.name"
                    :place-holder="'components.form.subscriptions.new.name.placeholder'"
                    :class-name="'background-light-blue-inputs'"
                    size="sm"
                    error-text="validation.text-input-three-characters"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits.credit-informations.type') }}
                </label>
              </b-col>
              <b-col class="mr-0 pr-0" cols="12">
                <select
                    v-model="credit.type"
                    size="sm"
                    class="form-control background-light-blue-inputs"
                >
                  <option value="place">{{ $t('components.form.credits.credit-informations.place-label') }}</option>
                  <option value="credit">{{ $t('components.form.credits.credit-informations.credit-label') }}</option>
                </select>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.availability') }}
                </label>
              </b-col>
              <b-col cols="12">
                <select
                    v-model="credit.enabled"
                    size="sm"
                    class="form-control background-light-blue-inputs"
                >
                  <option :value="true">{{ $t('general.actions.yes') }}</option>
                  <option :value="false">{{ $t('general.actions.no') }}</option>
                </select>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray" cols="3">
            {{ $t('components.form.credits.credit-informations.descriptions.name') }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="9">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.description.label') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-textarea
                    v-model="credit.description"
                    :placeholder="$t('components.form.credits.credit-informations.description-place-holder')"
                    rows="5"
                    size="sm"
                    class="background-light-blue-inputs"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray mt-3" cols="3">
            {{ $t('components.form.credits.credit-informations.descriptions.description') }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="9">
            <b-row class="mt-1">
              <b-col class="mt-4" align="center" cols="4">
                <label class="black-col-title mt-2">
                  {{ $t('components.form.credits.credit-informations.validity-duration') }}
                </label>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <b-row>
                      <b-col cols="12">
                        <label class="col-form-label-sm field-name-class">
                          {{ $t('components.form.credits.credit-informations.expiration') }}
                        </label>
                      </b-col>
                      <b-col cols="12">
                        <select
                            v-model="expiration"
                            size="sm"
                            class="form-control background-light-blue-inputs"
                        >
                          <option :value="true">{{ $t('general.actions.yes') }}</option>
                          <option :value="false">{{ $t('general.actions.no') }}</option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3">
                    <b-row>
                      <b-col cols="12">
                        <label class="col-form-label-sm field-name-class">
                          {{ $t('components.form.credits.credit-informations.duration') }}
                        </label>
                      </b-col>
                      <b-col cols="12">
                        <d-text-field
                            v-model="credit.validityDurationCount"
                            :min="1"
                            :rules="expiration ? validation.validityDurationCount : null"
                            place-holder="general.actions.hyphen"
                            :disabled="!expiration ? true: false"
                            class-name="background-light-blue-inputs"
                            size="sm"
                            type="number"
                            error-text="validation.sm-required"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col cols="12">
                        <label class="col-form-label-sm field-name-class">
                          {{ $t('components.form.credits.credit-informations.interval') }}
                        </label>
                      </b-col>
                      <b-col v-if="expiration" cols="12">
                        <select
                            v-model="credit.validityPeriodicityType"
                            size="sm"
                            :disabled="!expiration"
                            class="form-control background-light-blue-inputs"
                        >
                          <option disabled :value="null"> {{ $t('general.actions.choose') }}</option>
                          <option value="day">{{ $t('general.actions.day') }}</option>
                          <option value="week">{{ $t('general.actions.week.label') }}</option>
                          <option value="month">{{ $t('general.actions.months') }}</option>
                          <option value="year">{{ $t('general.actions.year') }}</option>
                        </select>
                        <b-form-invalid-feedback
                            :state="credit.validityPeriodicityType !== null"
                        >
                          {{ $t('validation.sm-required') }}
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col v-else cols="12">
                        <select
                            size="sm"
                            :disabled="true"
                            class="form-control background-light-blue-inputs"
                        >
                          <option disabled selected :value="null"> {{ $t('general.actions.choose') }}</option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray mt-3" cols="3">
            {{ $t('components.form.credits.credit-informations.descriptions.validity') }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {hydrate} from "@/utils/form";
import Credit from "@/classes/doinsport/Credit";

export default {
  data: () => ({
    expiration: false,
    deeped: false,
    loaded: false,
    model: new Credit(),
    validation: require('@validation/entities/doinsport/Credit.json')
  }),
  watch: {
    expiration: function () {
      if (this.credit.id === null) {
        if (!this.expiration) {
          this.credit.validityDurationCount = null;
          this.credit.validityPeriodicityType = null;
        } else {
          this.credit.validityPeriodicityType = 'month';
        }
      } else {
        if (this.loaded) {
          if (!this.expiration) {
            this.credit.validityDurationCount = null;
            this.credit.validityPeriodicityType = null;
          } else {
            this.credit.validityDurationCount = this.model.validityDurationCount;
            this.credit.validityPeriodicityType = this.model.validityPeriodicityType;
          }
        }
      }
    },
    credit: {
      deep: true,
      handler() {
        if (!this.deeped) {
          this.initExpiration();
          this.deeped = true;
        }
      }
    }
  },
  props: {
    credit: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    initExpiration() {
      if (this.credit.id) {
        hydrate(this.model, this.credit);

        if (this.credit.validityDurationCount === null && this.credit.validityPeriodicityType === null) {
          this.expiration = false;
        } else {
          this.expiration = true;
        }
      this.loaded = true;
      }
    }
  },
  beforeMount() {
    this.initExpiration();
  }
}
</script>


